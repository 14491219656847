// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mr-scanning-index-js": () => import("./../../../src/pages/mr-scanning/index.js" /* webpackChunkName: "component---src-pages-mr-scanning-index-js" */),
  "component---src-pages-nuklearmedicin-index-js": () => import("./../../../src/pages/nuklearmedicin/index.js" /* webpackChunkName: "component---src-pages-nuklearmedicin-index-js" */),
  "component---src-pages-roentgen-index-js": () => import("./../../../src/pages/roentgen/index.js" /* webpackChunkName: "component---src-pages-roentgen-index-js" */),
  "component---src-pages-simulatorer-index-js": () => import("./../../../src/pages/simulatorer/index.js" /* webpackChunkName: "component---src-pages-simulatorer-index-js" */),
  "component---src-pages-straalterapi-index-js": () => import("./../../../src/pages/straalterapi/index.js" /* webpackChunkName: "component---src-pages-straalterapi-index-js" */),
  "component---src-pages-ultraljud-index-js": () => import("./../../../src/pages/ultraljud/index.js" /* webpackChunkName: "component---src-pages-ultraljud-index-js" */)
}

